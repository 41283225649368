import React from 'react';
import { Banner } from './Banner';
import { PreferencesDialog } from './PreferencesDialog';
import { useDisclosure } from '@chakra-ui/react';

export type Container = {
  shouldShowBanner: boolean;
} & Pick<
  PreferencesDialog,
  'destinationPreferences' | 'destinations' | 'savePreferences'
> &
  Pick<Banner, 'onAcceptAll' | 'onDenyAll'>;

export const Container = (props: Container) => {
  const modalProps = useDisclosure();

  React.useLayoutEffect(() => {
    (window as any).__openSegmentDialog = () => modalProps.onOpen();
  }, []);

  return (
    <>
      {props.shouldShowBanner && (
        <Banner {...props} onOpenDialogClick={modalProps.onOpen} />
      )}
      <PreferencesDialog {...props} {...modalProps} />
    </>
  );
};
