import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import { Inter, Source_Code_Pro } from 'next/font/google';
import { ClerkProvider } from '@clerk/nextjs';
import { Box, ChakraProvider, LightMode } from '@chakra-ui/react';
import PlausibleProvider from 'next-plausible';
import { BottomCTAV2, Footer, Header, SentryLoader, SEO } from '@components';
import {
  AppAndInstanceSwitcherProvider,
  DocsNavigationProvider
} from 'context';
import theme from '@theme';
import { useSegment } from '@hooks/use-segment';
import 'focus-visible/dist/focus-visible'; // Remove Chakra's outline caused by clicks
import '@styles/index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@radix-ui/colors/black-alpha.css';
import '@radix-ui/colors/indigo.css';
import '@radix-ui/colors/mauve.css';
import '@radix-ui/colors/purple.css';
import '@radix-ui/colors/violet.css';
import { useSegmentIdentify } from '@hooks';
import { BannerProvider } from '@context/docs-banner';
import { MobileMenuClosed } from '@components/layout/header/mobile/mobile-menu-closed';
import { HelpButtonWrapper } from '@components/plain-support';
import { SyncTabInstancesProvider } from '@context/sync-tab-instances';
import { CookiesConsent } from '@components/cookies-consent/CookiesConsent';

type PreviewNoticeProps = {
  preview: boolean;
};

function PreviewNotice({ preview }: PreviewNoticeProps) {
  if (!preview) {
    return null;
  }

  return (
    <div className="absolute top-0 z-[9999999] w-full border-b border-gray-200 bg-white py-2 text-center">
      This page is a preview.{' '}
      <Link legacyBehavior href="/api/exit-preview" passHref>
        <a className="hover:text-cyan underline transition-colors duration-200">
          Click here
        </a>
      </Link>{' '}
      to exit preview mode.
    </div>
  );
}
const docsPages = [
  '/docs',
  '/docs/[category]/[...slug]',
  '/docs/[category]/overview',
  '/docs/[category]/[subcategory]/[...slug]',
  '/docs/[[...slug]]'
];
const noBottomCTA = [
  ...docsPages,
  '/privacy',
  '/startups',
  '/terms',
  '/pricing'
];

const newGradientPages = [
  '/company',
  '/privacy',
  '/pricing',
  '/',
  '/components',
  '/blog',
  '/tutorials',
  '/render'
];

const blogPages = ['/blog', '/blog/[...slug]'];

const marketingLandingPage = ['/lp/[slug]'];

const inter = Inter({ subsets: ['latin'] });
const sourceCodePro = Source_Code_Pro({ subsets: ['latin'] });

export default function MyApp({ Component, pageProps }: AppProps) {
  const analytics = useSegment();
  const { asPath, pathname } = useRouter();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get('utm_source') && document.referrer) {
      const referrerHostname = new URL(document.referrer).hostname;
      if (referrerHostname !== window.location.hostname) {
        const url = new URL(window.location.href);
        url.searchParams.set('utm_source', referrerHostname);
        url.searchParams.set('utm_medium', 'referral');
        url.searchParams.set('utm_campaign', 'none');
        window.history.replaceState({}, '', url);
      }
    }
  }, []);

  const EnsureUserContextDummyComponent = () => {
    useSegmentIdentify();
    return null;
  };

  useEffect(() => {
    // Get name from SEO
    if (asPath === '/') {
      analytics.page(`Marketing_Home_Viewed`, {
        surface: `Marketing`,
        location: `Home`
      });
    } else if (docsPages.includes(pathname)) {
      analytics.page(
        `Docs_${pageProps?.seo?.title}
        _Viewed`,
        {
          surface: `Docs`,
          location: pageProps?.seo?.title
        }
      );
    } else if (blogPages.includes(pathname)) {
      analytics.page(
        `Blog_${pageProps?.seo?.title}
        _Viewed`,
        {
          surface: `Blog`,
          location: pageProps?.seo?.title
        }
      );
    } else {
      analytics.page(`${pageProps?.seo?.title}_Viewed`, {
        surface: `Marketing`,
        location: pageProps?.seo?.title
      });
    }
  }, [asPath, pathname, pageProps?.seo?.title]);

  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
          --font-source-code-pro: ${sourceCodePro.style.fontFamily};
        }
      `}</style>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
          key="apple-touch-icon"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
          key="favicon-32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
          key="favicon-16"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" key="manifest" />
        <meta name="theme-color" content="#170f3e" key="theme" />
        <meta name="msapplication-TileColor" content="#170f3e" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#170f3e"
        />
        <SentryLoader />
      </Head>
      <ChakraProvider theme={theme}>
        <ClerkProvider>
          <EnsureUserContextDummyComponent />
          <HelpButtonWrapper>
            <SEO {...pageProps} />
            <PreviewNotice {...pageProps} />
            <PlausibleProvider domain={process.env.NEXT_PUBLIC_SITE_URL || ''}>
              <DocsNavigationProvider>
                <BannerProvider>
                  {docsPages.includes(pathname) ? (
                    <AppAndInstanceSwitcherProvider>
                      <SyncTabInstancesProvider>
                        <Box mb="-25px">
                          <Component {...pageProps} />
                        </Box>
                      </SyncTabInstancesProvider>
                    </AppAndInstanceSwitcherProvider>
                  ) : (
                    <>
                      {/* Prevent non-docs pages from using dark mode */}
                      {marketingLandingPage.includes(pathname) ? (
                        <LightMode>
                          <Component {...pageProps} />
                        </LightMode>
                      ) : (
                        <>
                          <Box display={{ base: 'none', nav: 'contents' }}>
                            <Header isSticky={pathname !== '/pricing'} />
                          </Box>
                          <LightMode>
                            <Box display={{ base: 'contents', nav: 'none' }}>
                              <MobileMenuClosed />
                            </Box>
                            <Box
                              mb="-25px"
                              overflowX={
                                pathname !== '/pricing' ? 'hidden' : 'visible'
                              }
                              id="page-content"
                              pt={
                                newGradientPages.includes(pathname)
                                  ? '0px'
                                  : pathname.includes('/blog/')
                                  ? { sm: '10px', md: '40px' }
                                  : '80px'
                              }
                            >
                              <Component {...pageProps} />

                              {pathname ===
                              '/pricing' ? null : !noBottomCTA.includes(
                                  pathname
                                ) ? (
                                <BottomCTAV2 />
                              ) : (
                                <Footer />
                              )}
                            </Box>
                          </LightMode>
                        </>
                      )}
                    </>
                  )}
                </BannerProvider>
              </DocsNavigationProvider>
            </PlausibleProvider>
          </HelpButtonWrapper>
          <CookiesConsent />
        </ClerkProvider>
      </ChakraProvider>
    </>
  );
}
